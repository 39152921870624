import styled from '@emotion/styled'
import peg from 'src/pegTheme'
import { css } from '@emotion/core'

export default styled.button`
  padding: ${ peg.spacing('s') } ${ peg.spacing('m') };
  border: none;
  background: ${ peg.color('blue') };
  box-shadow: 0 1px 0 ${ peg.color('blue.dark') };
  border-radius: ${ peg.get('borderRadius') };
  cursor: pointer;
  outline: none;
  transition: all 0.5s ease;
  font-weight: ${ peg.get('fontWeight.bold') };
  font-size: ${ peg.fs('m') };
  color: #fff;
  -webkit-font-smoothing: antialiased;
  font-family: inherit;
  position: relative;

  &:hover:not(:disabled) {
    color: #fff;
    background-color: ${ peg.color('blue.dark') };
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  ${ props => props.loading && css`
    color: transparent;
    pointer-events: none;

    &::before {
      content: '';
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 16px;
      height: 16px;
      margin-top: -8px;
      margin-left: -8px;
      border-radius: 50%;
      border: 2px solid rgba(255, 255, 255, 0.5);
      border-top-color: #fff;
      animation: spinner .6s linear infinite;
    }
  `}
`