import React from 'react'
import Layout from 'src/components/Layout'
import peg from 'src/pegTheme'
import RaisedButton from 'src/components/buttons/RaisedButton'
import styled from '@emotion/styled'
import alertIcon from 'src/images/icons/alert.svg'
import { Helmet } from 'react-helmet'
import { Title, StrapLine } from 'src/components/typography'

export default ({ location }) => (
  <Layout>
    <Helmet>
      <title>Talent Sign Up | Join Planet's Best Creators | Peg</title>
      <meta name='description' content='Sign up to our YouTube & Instagram marketing platform as a content creator, MCN or talent agent today to discover content creators that will give you the best return on influence' />
    </Helmet>

    <SignupSection>
      <SignupTitle>Sign Up to Peg for Talent</SignupTitle>
      <SignupStrapLine>For creators, talent agents and MCNs</SignupStrapLine>
      <WarningAlert><img src={ alertIcon } alt='' /> Sorry we've temporarily paused talent signup, please check back soon.</WarningAlert>

      <Benefits>
        <p>By claiming your verified account on Peg you'll get access to a truckload of exclusive benefits, including:</p>
        <ul>
          <li>Finding and contacting other creators and brands</li>
          <li>Having a beautiful, professional profile to showcase your work</li>
          <li>Customising your profile, or just sitting back and letting it build itself for you</li>
          <li>Being significantly more discoverable to some of the best brands on the planet</li>
        </ul>
      </Benefits>

      <RaisedButton disabled>Sign Up</RaisedButton>
    </SignupSection>
  </Layout>
)


const SignupSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  display: flex;
  padding: 40px 0;
  flex-direction: column;
  align-items: center;
`

const SignupTitle = styled(Title)`
  margin: ${ peg.spacing('m') } 0 0;
`

const SignupStrapLine = styled(StrapLine)`
  margin-top: 0;
`

const WarningAlert = styled.div`
  background-color: ${ peg.color('orange') };
  border-radius: ${ peg.get('borderRadius') };
  padding: ${ peg.spacing('m') } ${ peg.spacing('l') };
  color: #fff;
  font-weight: ${ peg.get('fontWeight.bold') };
  margin: ${ peg.spacing('l') } 0;

  img {
    height: 24px;
    vertical-align: -7px;
    margin-right: ${ peg.spacing('xs') };
  }
`

const Benefits = styled.div`
  max-width: 400px;
  margin-bottom: ${ peg.spacing('l') };

  ul {
    color: ${ peg.color('grey') };
  }

  li {
    margin: ${ peg.spacing('s') } 0;
    line-height: 150%;
  }
`